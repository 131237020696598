import { createRouter, createWebHistory } from 'vue-router';

const TelaCarregamento = () => import('../views/TelaCarregamento.vue');
const TelaLogin = () => import('../views/TelaLogin.vue');
const TelaLoginAdm = () => import('../views/TelaLoginAdm.vue');
const TelaAdm = () => import('../views/TelaAdm.vue');
const TelaAlert = () => import('../views/TelaAlert.vue');
const TelaProdutos = () => import('../views/TelaProdutos.vue');
const IncluirProdutos = () => import('../views/IncluirProdutos.vue');
const ProdutosDescricao = () => import('../views/ProdutosDescricao.vue');
const ProdutosEdit = () => import('../views/ProdutosEdit.vue');
const AlertValidade = () => import('../views/AlertValidade.vue');
const VerificacaoValidades = () => import('../views/VerificacaoValidades.vue');

const routes = [
    {
        path: '/',
        name: 'login',
        component: TelaLogin,
        meta: { title: 'semHeader', hasArrow: false, hasNoRefresh: true, isLogin: true },
    },
    {
        path: '/login/adm',
        name: 'login.adm',
        component: TelaLoginAdm,
        meta: { title: 'semHeader', hasArrow: false, hasNoRefresh: true, isLogin: true },
    },
    {
        path: '/adm',
        name: 'adm',
        component: TelaAdm,
        meta: { title: 'semHeader', hasArrow: false, hasNoRefresh: true },
    },
    {
        path: '/carregamento',
        name: 'carregamento',
        component: TelaCarregamento,
        meta: { title: 'semHeader', hasArrow: false, hasNoRefresh: true },
    },
    {
        path: '/alert',
        name: 'alert',
        component: TelaAlert,
        meta: { title: 'Alertas de Validade', hasArrow: false, hasNoRefresh: false, isProduto: false, isAlerta: true },
    },
    {
        path: '/produtos',
        name: 'produtos',
        component: TelaProdutos,
        meta: { title: 'Incluir Produtos', hasArrow: false, hasNoRefresh: false, isProduto: true, isAlerta: false },
    },
    {
        path: '/produtos/descricao',
        name: 'produtos.descricao',
        component: ProdutosDescricao,
        meta: { title: 'Inclusão de Produto', hasArrow: true, hasNoRefresh: false, isProduto: true, isAlerta: false },
    },
    {
        path: '/incluirProdutos',
        name: 'incluirProdutos',
        component: IncluirProdutos,
        meta: { title: 'Inclusão de Produto', hasArrow: true, hasNoRefresh: true, isSave: true },
    },
    {
        path: '/produtos/edit',
        name: 'produtos.edit',
        component: ProdutosEdit,
        meta: { title: 'Inclusão de Produto', hasArrow: true, hasNoRefresh: true, isSave: true },
    },
    {
        path: '/alert/validade',
        name: 'alert.validade',
        component: AlertValidade,
        meta: { title: 'Alertas de Validade', hasArrow: true, hasNoRefresh: false, isProduto: false, isAlerta: true },
    },
    {
        path: '/verificacao/validades',
        name: 'verificacao.validades',
        component: VerificacaoValidades,
        meta: { title: 'Verificação de Validades', hasArrow: true, hasNoRefresh: true, isSave: true },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    let currentRoute = to.name;

    if (currentRoute == 'adm' || currentRoute == 'login.adm') {
        let user = sessionStorage.getItem('usuario');

        if (currentRoute == 'login.adm') {
            next();
        } else if (user == 'admcondor') {
            next();
        } else {
            router.push({ name: 'login.adm' });
        }
    } else if (currentRoute !== 'login') {
        let user = sessionStorage.getItem('usuario');

        if (user) {
            next();
        } else {
            router.push({ name: 'login' });
        }
    } else {
        next();
    }
});

export default router;
