const inclusaoStore = {
    state() {
        return {
            produtos: '',
            codigo_de_barras: '',
            validade: '',
            tipo_alerta: 3,
        };
    },
    mutations: {
        setProdutos(state, payload) {
            state.produtos = payload;
        },
        setNovoProduto(state, payload) {
            let produtos = state.produtos;

            let produtoExiste = produtos.filter((produto) => produto.codigo_de_barras == payload.codigo_de_barras);

            if (produtoExiste.length > 0) {
                let arrayValidades = payload.validadesAdicionais;
                arrayValidades.forEach((element) => {
                    let data = {
                        quantidade: element.quantidade,
                        validade: element.validade,
                    };

                    produtoExiste[0].validadesAdicionais.push(data);
                });
            } else {
                produtos.push(payload);
            }
        },
        setCodigo(state, payload) {
            state.codigo_de_barras = payload;
        },
        setValidade(state, payload) {
            state.validade = payload;
        },
        setTipoAlerta(state, payload) {
            state.tipo_alerta = payload;
        },
    },
    actions: {
        setProdutos(context, payload) {
            context.commit('setProdutos', payload);
        },
        setNovoProduto(context, payload) {
            context.commit('setNovoProduto', payload);
        },
        setCodigo(context, payload) {
            context.commit('setCodigo', payload);
        },
        setValidade(context, payload) {
            context.commit('setValidade', payload);
        },
        setTipoAlerta(context, payload) {
            context.commit('setTipoAlerta', payload);
        },
    },
    getters: {
        getProdutos(state) {
            let produtos = state.produtos;

            return produtos.reduce(function (key, element) {
                key[element.categoria] = key[element.categoria] || [];
                key[element.categoria].push(element);
                return key;
            }, Object.create(null));
        },
        getProduto(state) {
            let prod = state.produtos;

            let filtrados = prod.filter((element) => {
                return element.codigo_de_barras == state.codigo_de_barras;
            });

            return filtrados[0];
        },
        getCodigo(state) {
            return state.codigo_de_barras;
        },
        getValidade(state) {
            return state.validade;
        },
        getTipoAlerta(state) {
            return state.tipo_alerta;
        },
    },
};

export default inclusaoStore;
